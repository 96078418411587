import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './util/i18n';
import { Provider } from 'react-redux';
import store from './store';
import RouterPage from './routerpage';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(  
  <Provider store={store}>
    <RouterPage />
  </Provider>
);