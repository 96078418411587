import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { fetchComments } from '../../store/feature/commentSlice';
import './CommentSlider.css';
import { AppDispatch } from '../../store';

const CommentSlider = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { comments, loading, error } = useSelector((state: any) => state.comments);

  useEffect(() => {
    dispatch(fetchComments());
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>Hata: {error}</p>;

  return (
    <div className="comment-slider-carousel">
      <h2>Hakkımızda Yorumlar</h2>
      {comments.length > 0 ? (
        <Slider {...settings}>
          {comments.map((comment: any, index: number) => (
            <div className="comment-card" key={index}>
              <h3>
                {`${comment.name.slice(0, 2)}***`} {`${comment.surname.slice(0, 2)}***`}
              </h3>
              <p>
                {comment.comment.length > 100
                  ? `${comment.comment.slice(0, 100)}...`
                  : comment.comment}
              </p>
              <span>{comment.companyName || 'Firma bilgisi yok'}</span>
            </div>
          ))}
        </Slider>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default CommentSlider;
