import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { fetchProjects } from '../store/feature/projectSlice';
import { Link } from 'react-router-dom';
import './Project.css';
import { AppDispatch } from '../store';

const CompletedProjects = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { projects, loading, error } = useSelector((state: any) => state.projects);

  // Proje açıklamasını formatlamak için yardımcı fonksiyon
  const formatContent = (rawContent: string) => {
    return rawContent
      .split('\n') // Yeni satırlara göre ayır
      .map((line) => line.trim()) // Her satırdaki gereksiz boşlukları temizle
      .filter((line) => line !== "") // Boş satırları çıkar
      .map((line) => `<p>${line}</p>`) // Her satırı <p> etiketine sar
      .join(""); // Tek bir string olarak birleştir
  };

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>Hata: {error}</p>;

  return (
    <div className="completed-projects-carousel">
      <h2>Tamamlanan Projelerimiz</h2>
      {projects.length > 1 ? (
        <Slider {...settings}>
          {projects.map((project: any, index: number) => (
            <Link
              to={`/project/${encodeURIComponent(project.title)}`}
              className="project-card"
              key={index}
            >
              <h3>{project.title}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: formatContent(
                    project.description.length > 100
                      ? `${project.description.slice(0, 100)}...`
                      : project.description
                  ),
                }}
              ></div>
            </Link>
          ))}
        </Slider>
      ) : (
        projects.map((project: any, index: number) => (
          <div className="project-card" key={index}>
            <h3>{project.title}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: formatContent(
                  project.description.length > 50
                    ? `${project.description.slice(0, 50)}...`
                    : project.description
                ),
              }}
            ></div>
          </div>
        ))
      )}
    </div>
  );
};

export default CompletedProjects;
