import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'linear-gradient(to right, #6a11cb, #2575fc)',
        color: '#fff',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '96px', fontWeight: 'bold', marginBottom: '16px' }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ fontSize: '24px', marginBottom: '24px' }}>
        Üzgünüz, aradığınız sayfa bulunamadı.
      </Typography>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="contained" sx={{ backgroundColor: '#fff', color: '#6a11cb', fontWeight: 'bold', ':hover': { backgroundColor: '#e3e3e3' } }}>
          Anasayfaya Dön
        </Button>
      </Link>
    </Box>
  );
};

export default NotFoundPage;
