import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAboutUs } from '../store/feature/aboutusSlice';
import { AppDispatch, RootState } from '../store'; // Redux store türlerini içe aktarın
import './About.css';

const About: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { content, loading, error } = useSelector((state: RootState) => state.aboutus);

  // Redux'tan içeriği yükle
  useEffect(() => {
    dispatch(fetchAboutUs());
  }, [dispatch]);

  // HTML etiketlerini kaldırmak ve içeriği 500 karakterle sınırlandırmak
  const truncateAndCleanContent = (htmlContent: string | null) => {
    if (!htmlContent) return '';
    const plainText = htmlContent.replace(/<[^>]*>?/gm, ''); // HTML etiketlerini kaldır
    return `${plainText.slice(0, 300)}...`; // Karakter sınırlaması uygula
  };

  const truncatedContent = truncateAndCleanContent(content);

  return (
    <div className="about-us">
      <h2>Biz Kimiz?</h2>
      {loading && <p>Yükleniyor...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!loading && !error && (
        <>
          <p>{truncatedContent || "Henüz bir içerik bulunmuyor. Lütfen daha sonra tekrar kontrol ediniz."}</p>
          <button onClick={() => (window.location.href = "/hakkimizda")}>
            Daha fazlası için
          </button>
        </>
      )}
    </div>
  );
};

export default About;
