import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddresses } from '../store/feature/adressSlice';
import { fetchPhones } from '../store/feature/phoneSlice';
import { AppDispatch } from '../store';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import './Contact.css';
import AppBar from '../components/molecules/Appbar';
import Footer from '../components/molecules/Footer';
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { addresses, loading: loadingAddresses, error: addressError } = useSelector(
      (state: any) => state.adress
  );
  const { phones, loading: loadingPhones, error: phoneError } = useSelector(
      (state: any) => state.phoneSlice
  );
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(fetchAddresses(token));
      dispatch(fetchPhones());
    } else {
      console.error('Token not found');
    }
  }, [dispatch]);

  if (addressError || phoneError) {
    return <div className="error">Error: {addressError || phoneError}</div>;
  }

  return (
      <div>
        <AppBar />
        <div className="contact-page">
          <div className="contact-container">
            <h2 className="contact-title">{t('communication.communication')}</h2>

            {/* Loading State */}
            {loadingAddresses || loadingPhones ? (
                <div className="loading">Loading...</div>
            ) : (
                <>
                  {/* Addresses Section */}
                  <div className="addresses-section">
                    <h3 className="section-title">
                      <FaMapMarkerAlt /> {t('communication.addresses')}
                    </h3>
                    {addresses.length === 0 ? (
                        <p className="no-data">No addresses found</p>
                    ) : (
                        <ul className="address-list">
                          {addresses.map((address: { id: number; description: string; value: string }) => (
                              <li key={address.id} className="address-item">
                                <strong>{address.description}</strong> {address.value}
                              </li>
                          ))}
                        </ul>
                    )}
                  </div>

                  <hr className="divider" />

                  {/* Phones Section */}
                  <div className="phones-section">
                    <h3 className="section-title">
                      <FaPhoneAlt /> {t('communication.phonenumbers')}
                    </h3>
                    {phones.length === 0 ? (
                        <p className="no-data">No phone numbers found</p>
                    ) : (
                        <ul className="phone-list">
                          {phones.map((phone: { id: number; description: string; value: string }) => (
                              <li key={phone.id} className="phone-item">
                                <strong>{phone.description}</strong>{' '}
                                <a href={`tel:${phone.value}`} className="phone-link">
                                  {phone.value}
                                </a>
                              </li>
                          ))}
                        </ul>
                    )}
                  </div>

                  <hr className="divider" />

                  {/* Email Section */}
                  <div className="email-section">
                    <h3 className="section-title">
                      <FaEnvelope /> E-mail
                    </h3>
                    <p className="email-item">
                      <a href="mailto:isttekzemin@gmail.com" className="email-link">
                        isttekzemin@gmail.com
                      </a>
                    </p>
                  </div>
                </>
            )}
          </div>
        </div>
        <Footer />
      </div>
  );
};

export default Contact;
