import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../constants/Config';

// API URL
const FULL_BASE_URL = `${BASE_URL}/dev/v1/adress`;

// Adresleri çekmek için thunk
export const fetchAddresses = createAsyncThunk(
  'address/fetchAddresses',
  async (token: string | undefined) => {
    if (!token) throw new Error('Token bulunamadı');
    const response = await axios.get(`${FULL_BASE_URL}/findall`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

// Adres kaydetmek için thunk
export const saveAddress = createAsyncThunk(
  'address/saveAddress',
  async ({ token, description, value }: { token: string | undefined; description: string; value: string }) => {
    if (!token) throw new Error('Token bulunamadı');
    const response = await axios.post(`${FULL_BASE_URL}/save`, { token, description, value });
    return response.data;
  }
);

// Adres silmek için thunk
export const deleteAddress = createAsyncThunk(
  'address/deleteAddress',
  async ({ token, id }: { token: string | undefined; id: number }) => {
    if (!token) throw new Error('Token bulunamadı');
    const response = await axios.delete(`${FULL_BASE_URL}/delete`, {
      params: { token, id },
    });
    return id; // Silinen adresin id'si dönecek
  }
);

// Slice
const addressSlice = createSlice({
  name: 'address',
  initialState: {
    addresses: [] as Array<{ id: number; description: string; value: string }>,
    loading: false,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddresses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.loading = false;
        state.addresses = action.payload;
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Beklenmeyen hata';
      })
      .addCase(saveAddress.fulfilled, (state, action) => {
        state.addresses.push(action.payload); // Yeni adresi listeye ekleyin
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.addresses = state.addresses.filter((address) => address.id !== action.payload);
      });
  },
});

export default addressSlice.reducer;
