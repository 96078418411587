import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { fetchForgotPassword, fetchResetPassword } from '../store/feature/authSlice';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import Swal from "sweetalert2";

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const [showResetCard, setShowResetCard] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [timer, setTimer] = useState(120); // Timer başlangıcı (2 dakika)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [newPassword, setNewPassword] = useState('');
    const [rePassword, setrePassword] = useState('');
    const [code, setCode] = useState<string | null>(null);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (showResetCard && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }

        if (timer === 0 && intervalId) {
            clearInterval(intervalId);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [showResetCard, timer]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // E-posta formatı kontrolü
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
            Swal.fire('Hata', 'Lütfen geçerli bir e-posta adresi giriniz.', 'error');
            return;
        }

        // Şifre sıfırlama isteğini gönder
        dispatch(fetchForgotPassword({ email }))
            .unwrap()
            .then(() => setShowResetCard(true)) // Başarıyla e-posta gönderildiyse reset kartını göster
            .catch((err) => Swal.fire('Hata', 'Bir hata oluştu: ' + err, 'error')); // Hata durumunda bildirim
    };

    const sendNewPassword = () => {
        if (code && newPassword && rePassword) {
            if (newPassword !== rePassword) {
                Swal.fire('Hata', 'Yeni şifreler birbirini tutmuyor.', 'error');
                return;
            }

            dispatch(fetchResetPassword({
                code: code,
                newPassword: newPassword,
                rePassword: rePassword
            })).then(data => {
                if (data.payload.code === 200) {
                    Swal.fire('Başarılı', 'Şifreniz başarıyla değiştirildi', 'success')
                        .then(() => navigate('/admin'));
                } else {
                    Swal.fire('Hata', 'Şifre değiştirme işlemi başarısız oldu', 'error');
                }
            }).catch((err) => {
                Swal.fire('Hata', 'Bir hata oluştu: ' + err, 'error');
            });
        } else {
            Swal.fire('Hata', 'Lütfen tüm alanları doldurduğunuzdan emin olun.', 'error');
        }
    };

    const handleTimeExpired = () => {
        Swal.fire('Hata', 'Kod süresi doldu, lütfen tekrar deneyin.', 'error');
        navigate("/forgot-password"); // Kullanıcı yeniden şifre sıfırlama akışına yönlendirilir.
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                background: "linear-gradient(to right, #6a11cb, #2575fc)",
                padding: 2,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: 500,
                    margin: "auto",
                    padding: 4,
                    borderRadius: 3,
                    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
                    backgroundColor: "white",
                    textAlign: "center",
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3 }}>
                    Şifremi Unuttum
                </Typography>

                {!showResetCard ? (
                    <>
                        <Typography sx={{ mb: 3, fontSize: "1.1rem", color: "gray" }}>
                            Şifrenizi sıfırlamak için e-posta adresinizi giriniz.
                        </Typography>
                        <TextField
                            fullWidth
                            label="E-posta Adresiniz"
                            variant="outlined"
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                mt: 3,
                                backgroundColor: "#2575fc",
                                "&:hover": { backgroundColor: "#6a11cb" },
                                padding: "15px",
                                fontSize: "1.1rem",
                            }}
                            onClick={handleSubmit}
                        >
                            Şifre Sıfırlama Kodunu al
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography sx={{ mb: 2, fontSize: "1.1rem", color: "gray" }}>
                            Kodun geçerlilik süresi: {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}{timer % 60}
                        </Typography>

                        {timer === 0 ? (
                            <Typography
                                color="error"
                                sx={{ mb: 3, cursor: "pointer", textDecoration: "underline" }}
                                onClick={handleTimeExpired}
                            >
                                Kod süresi doldu. Lütfen tekrar deneyin.
                            </Typography>
                        ) : (
                            <>
                                <TextField
                                    fullWidth
                                    label="E-posta Adresinize Gelen Kod"
                                    variant="outlined"
                                    margin="normal"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    fullWidth
                                    label="Yeni Şifre"
                                    type={showPassword ? "text" : "password"}
                                    variant="outlined"
                                    margin="normal"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    sx={{ mb: 2 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassword((prev) => !prev)
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Yeni Şifreyi Tekrar Girin"
                                    type={showConfirmPassword ? "text" : "password"}
                                    variant="outlined"
                                    margin="normal"
                                    value={rePassword}
                                    onChange={(e) => setrePassword(e.target.value)}
                                    sx={{ mb: 3 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowConfirmPassword((prev) => !prev)
                                                    }
                                                >
                                                    {showConfirmPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        mt: 3,
                                        backgroundColor: "#2575fc",
                                        "&:hover": { backgroundColor: "#6a11cb" },
                                        padding: "15px",
                                        fontSize: "1.1rem",
                                    }}
                                    onClick={sendNewPassword}
                                >
                                    Şifremi Sıfırla
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ForgotPassword;
