import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveProject } from '../../store/feature/projectSlice';
import { Button, TextField, Box } from '@mui/material';
import Swal from 'sweetalert2';
import { AppDispatch } from '../../store';

const ProjectForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [employer, setEmployer] = useState('');
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = localStorage.getItem('token');

  const formatContent = (rawContent: string) => {
    return rawContent
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line !== '')
      .map((line) => `<p>${line}</p>`)
      .join('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title || !description || !employer || !location || !date) {
      Swal.fire({
        icon: 'warning',
        title: 'Eksik Alanlar',
        text: 'Lütfen tüm gerekli alanları doldurun!',
      });
      return;
    }

    if (isSubmitting) return;

    setIsSubmitting(true);

    const projectData = {
      token: token || '',
      employer,
      title,
      location,
      date,
      description: formatContent(description),
    };

    try {
      await dispatch(saveProject(projectData)).unwrap();
      Swal.fire({
        icon: 'success',
        title: 'Proje Eklendi',
        text: 'Proje başarıyla eklendi!',
      });
      setEmployer('');
      setTitle('');
      setLocation('');
      setDate('');
      setDescription('');
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: error.message || 'Proje eklenirken bir hata oluştu!',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      sx={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}
    >
      <TextField
        label="İşveren"
        variant="outlined"
        fullWidth
        value={employer}
        onChange={(e) => setEmployer(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Proje Başlığı"
        variant="outlined"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Konum"
        variant="outlined"
        fullWidth
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Tarih"
        variant="outlined"
        fullWidth
        value={date}
        onChange={(e) => setDate(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Proje Açıklaması"
        variant="outlined"
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        rows={4}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Kaydediliyor...' : 'Projeyi Kaydet'}
      </Button>
    </Box>
  );
};

export default ProjectForm;
