import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveService } from '../store/feature/ourservicesSlice';
import { Button, TextField, Box } from '@mui/material';
import Swal from 'sweetalert2';
import { AppDispatch } from '../store';

const OurServicesForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [photo, setPhoto] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const token = localStorage.getItem('token');

  // Metin formatlama fonksiyonu
  const formatContent = (rawContent: string) => {
    return rawContent
      .split('\n') // Yeni satırlara göre ayır
      .map((line) => line.trim()) // Her satırdaki gereksiz boşlukları temizle
      .filter((line) => line !== "") // Boş satırları çıkar
      .map((line) => `<p>${line}</p>`) // Her satırı <p> etiketine sar
      .join(""); // Tek bir string olarak birleştir
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title || !description) {
      Swal.fire({
        icon: 'warning',
        title: 'Eksik Alanlar',
        text: 'Lütfen tüm gerekli alanları doldurun!',
      });
      return;
    }

    const formData = new FormData();
    if (photo) formData.append('photo', photo);
    formData.append('title', title);
    formData.append('description', formatContent(description)); // Açıklamayı formatla
    if (token) formData.append('token', token);

    try {
      await dispatch(saveService(formData)).unwrap(); // Dispatch saveService action
      Swal.fire({
        icon: 'success',
        title: 'Hizmet Eklendi',
        text: 'Hizmet başarıyla eklendi!',
      });
      // Form alanlarını sıfırla
      setPhoto(null);
      setTitle('');
      setDescription('');
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: error.message || 'Hizmet eklenirken bir hata oluştu!',
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      sx={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}
    >
      <TextField
        label="Hizmet Başlığı"
        variant="outlined"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        sx={{ marginBottom: '16px' }}
      />
      <TextField
        label="Hizmet Açıklaması"
        variant="outlined"
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        rows={4} // Açıklama girişi için daha fazla alan
        sx={{ marginBottom: '16px' }}
      />
      <input
        type="file"
        accept="image/*"
        onChange={(e) => setPhoto(e.target.files ? e.target.files[0] : null)}
        required
        style={{ marginBottom: '16px' }}
      />
      <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>
        Hizmeti Kaydet
      </Button>
    </Box>
  );
};

export default OurServicesForm;
