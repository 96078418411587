import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../store';
import { Box, Typography } from '@mui/material';
import AppBar from '../components/molecules/Appbar';
import Footer from '../components/molecules/Footer';

const ServiceDetailPage: React.FC = () => {
  const { title } = useParams<{ title: string }>();
  const service = useSelector((state: RootState) =>
    state.ourservices.services.find(
      (service) => service.title === decodeURIComponent(title || '') // title decode edildi
    )
  );

  if (!service) {
    return <div>Hizmet bulunamadı.</div>;
  }

  return (
    <div style={styles.container}>
      <AppBar />
      <Box className="project-details" sx={{ padding: '20px', textAlign: 'center', flexGrow: 1 }}>
        {service.photoUrl ? (
          <img
            src={service.photoUrl}
            alt={service.title}
            className="project-photo"
            style={{
              width: '80%',
              maxWidth: '400px',
              margin: '20px auto',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          />
        ) : (
          <p>Fotoğraf mevcut değil.</p>
        )}
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
          {service.title}
        </Typography>
        <Typography
          sx={{ fontSize: '1.1em', color: '#666', marginTop: '16px' }}
          dangerouslySetInnerHTML={{ __html: service.description }} // Render HTML content
        />
      </Box>
      <Footer />
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensure the container takes up at least the full viewport height
    fontFamily: 'Arial, sans-serif',
    overflowX: 'hidden',  // Prevent horizontal overflow
    width: '100%',
    boxSizing: 'border-box',
  },
};

export default ServiceDetailPage;
