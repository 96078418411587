import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../constants/Config';

const FULL_BASE_URL = `${BASE_URL}/dev/v1/comment`;

interface CommentState {
  loading: boolean;
  error: string | null;
  success: boolean;
  comments: any[]; // Gelen yorumları tutacak dizi
}

const initialState: CommentState = {
  loading: false,
  error: null,
  success: false,
  comments: [],
};

// Yorum gönderme işlemi
export const saveComment = createAsyncThunk(
  'comments/saveComment',
  async (commentData: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${FULL_BASE_URL}/save`, commentData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Yorumları çekme işlemi
export const fetchComments = createAsyncThunk(
  'comments/fetchComments',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${FULL_BASE_URL}/findall`);
      console.log('API Response:', response.data); // Gelen yanıtı loglayın
      if (Array.isArray(response.data)) {
        return response.data; // Gelen yanıt bir dizi ise döndür
      } else {
        throw new Error('Beklenmeyen yanıt formatı');
      }
    } catch (error: any) {
      console.error('Fetch Comments Error:', error);
      return rejectWithValue(error.response?.data?.message || 'Yorumlar alınamadı');
    }
  }
);

// Yorumu onaylama işlemi
export const approveComment = createAsyncThunk(
  'comments/approveComment',
  async ({ commentId, token }: { commentId: number; token: string }, { rejectWithValue }) => {
    console.log('Comment ID:', commentId);
    console.log('Token:', token);
      try {
          const response = await axios.put(`${FULL_BASE_URL}/approve`, {
              id: commentId, // Burada id kullanmalıyız.
              token,
          });
          return { commentId, success: response.data.success };
      } catch (error: any) {
          return rejectWithValue(error.response.data.message);
      }
  }
);


// Yorumu silme işlemi
export const deleteComment = createAsyncThunk(
  'comments/deleteComment',
  async ({ token, commentId }: { token: string; commentId: number }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${FULL_BASE_URL}/delete`, {
        data: { token, commentId },
      });
      return commentId;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetCommentState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.loading = false;
        state.comments = action.payload;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(saveComment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(saveComment.fulfilled, (state) => {
        state.loading = false;
        state.success = true; // Yorum başarıyla kaydedildi
      })
      .addCase(saveComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string; // Hata mesajı
      })
      .addCase(approveComment.fulfilled, (state, action) => {
        const comment = state.comments.find((c) => c.id === action.payload.commentId);
        if (comment) comment.status = 'ACTIVE';
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.comments = state.comments.filter((c) => c.id !== action.payload);
      });
  },
});

export const { resetCommentState } = commentSlice.actions;
export default commentSlice.reducer;
