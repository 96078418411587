import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, deleteProject } from '../../store/feature/projectSlice';
import { AppDispatch, RootState } from '../../store';
import { Box, Typography, Button } from '@mui/material';

const ProjectsList = () => {
  const dispatch: AppDispatch = useDispatch();
  const projects = useSelector((state: RootState) => state.projects.projects);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const handleDelete = (id: number) => {
    const token = localStorage.getItem('token'); // JWT token from localStorage
    if (!token) {
      alert('Please login first.');
      return;
    }
    dispatch(deleteProject({ projectId: id, token })).then(() => {
      dispatch(fetchProjects()); // Update list after deletion
    });
  };

  // Function to truncate description to 200 characters
  const truncateDescription = (text: string) => {
    return text.length > 200 ? text.substring(0, 200) + '...' : text;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
      }}
    >
      {(Array.isArray(projects) ? projects : []).map((project) => (
        <Box
          key={project.id}
          sx={{
            width: '300px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: '380px', // Ensure all cards have equal height
          }}
        >
          
          <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              {project.title}
            </Typography>
            <Typography
              sx={{
                color: 'gray',
                marginBottom: '12px',
                height: '80px', // Restricts the description area height
                overflow: 'hidden', // Hide overflowed text
                textOverflow: 'ellipsis', // Add ellipsis at the end
                whiteSpace: 'normal', // Ensures text wraps properly
              }}
              dangerouslySetInnerHTML={{
                __html: truncateDescription(project.description),
              }}
            />
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(project.id)}
            >
              Sil
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ProjectsList;
