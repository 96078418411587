import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchComments,
  approveComment,
  deleteComment,
} from '../../store/feature/commentSlice';
import { AppDispatch } from '../../store';
import './AdminCommentPage.css'; // Importing CSS file for styling

const AdminCommentPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { comments, loading, error } = useSelector((state: any) => state.comments);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      dispatch(fetchComments());
    } else {
      console.error('Token bulunamadı!');
    }
  }, [dispatch, token]);

  const handleApprove = (commentId: number) => {
    if (token) {
      dispatch(approveComment({ commentId, token }));
    } else {
      console.error('Token bulunamadı!');
    }
  };

  const handleDelete = (commentId: number) => {
    if (token) {
      dispatch(deleteComment({ token, commentId }));
    } else {
      console.error('Token bulunamadı!');
    }
  };

  const pendingComments = comments.filter((comment: any) => comment.status === 'PENDING');
  const approvedComments = comments.filter((comment: any) => comment.status === 'ACTIVE');

  return (
    <div className="comment-management">
      <h2>Yorum Yönetimi</h2>
      {loading && <p>Yükleniyor...</p>}
      {error && <p className="error">{error}</p>}

      {/* Pending Comments */}
      <h3>PENDING Yorumlar</h3>
      <table className="comment-table">
        <thead>
          <tr>
            <th>Ad Soyad</th>
            <th>E-posta</th>
            <th>Yorum</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {pendingComments.map((comment: any) => (
            <tr key={comment.id}>
              <td>{`${comment.name} ${comment.surname}`}</td>
              <td>{comment.email}</td>
              <td>{comment.comment}</td>
              <td>{comment.status}</td>
              <td>
                <button className="icon-button approve" onClick={() => handleApprove(comment.id)}>
                  ✔️
                </button>
                <button className="icon-button delete" onClick={() => handleDelete(comment.id)}>
                  ❌
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Approved Comments */}
      <h3>Onaylanmış Yorumlar</h3>
      <table className="comment-table">
        <thead>
          <tr>
            <th>Ad Soyad</th>
            <th>E-posta</th>
            <th>Yorum</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {approvedComments.map((comment: any) => (
            <tr key={comment.id}>
              <td>{`${comment.name} ${comment.surname}`}</td>
              <td>{comment.email}</td>
              <td>{comment.comment}</td>
              <td>{comment.status}</td>
              <td>
                <button className="icon-button delete" onClick={() => handleDelete(comment.id)}>
                  ❌
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminCommentPage;
