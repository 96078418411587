import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../constants/Config';

const FULL_BASE_URL = `${BASE_URL}/dev/v1/ourservice`;

export interface Service {
  id: number;
  title: string;
  description: string;
  photoUrl: string; // Backend'in döndürdüğü URL
}


  interface OurServicesState {
    services: Service[];
    loading: boolean;
    error: string | null;
  }
  
  const initialState: OurServicesState = {
    services: [], // Boş dizi başlangıç durumu
    loading: false,
    error: null,
  };

// Define async actions (thunks) for API calls
export const fetchservices = createAsyncThunk(
    'ourservices/fetchservices',
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch(`${FULL_BASE_URL}/findall`);
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        const data = await response.json();
        return data; // Direkt data döndür
      } catch (error: any) {
        return rejectWithValue(error.message || 'Unknown error');
      }
    }
  );
  


export const saveService = createAsyncThunk(
  'ourServices/save',
  async (serviceData: FormData) => {
    const response = await axios.post(`${FULL_BASE_URL}`, serviceData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }
);

export const deleteService = createAsyncThunk(
    'ourServices/delete',
    async ({ ourServicesId, token }: { ourServicesId: number; token: string }) => {
      const response = await axios.delete(`${FULL_BASE_URL}/delete`, {
        headers: {
          'Content-Type': 'application/json', // JSON formatında veri gönderiyoruz
        },
        data: { 
          token,               // Token'ı body içinde gönderiyoruz
          ourServicesId        // Silinecek hizmetin ID'si
        },
      });
  
      if (!response.data || !response.data.data) {
        throw new Error('Hizmet silinemedi.');
      }
  
      return ourServicesId; // Redux state'den silinmek üzere ID döndür
    }
  );
  
  
  

// Define initial state


// Create slice
const ourServicesSlice = createSlice({
  name: 'ourServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchservices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchservices.fulfilled, (state, action) => {
        console.log('Redux payload:', action.payload); // Gelen payload'u kontrol edin
        state.services = action.payload;
        state.loading = false;
      })
      .addCase(fetchservices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(saveService.fulfilled, (state, action: PayloadAction<Service[]>) => {
        console.log('Payload in reducer:', action.payload);
        state.services = action.payload;
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.services = state.services.filter(
          (service) => service.id !== action.payload
        )      
      });
  },
});

export default ourServicesSlice.reducer;
