import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import logo from "../../images/ist-tek_zemin.png";
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { FlagIcon } from 'react-flag-kit';
import { fetchPhones } from '../../store/feature/phoneSlice'; // Import the action
import { AppDispatch, RootState } from '../../store'; // Correct imports for your store

const AppBar = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>(); // Type the dispatch correctly
  const [isNavbarSticky, setIsNavbarSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Access phone data from Redux store
  const { phones, loading } = useSelector((state: RootState) => state.phoneSlice); // Use RootState for correct typing

  useEffect(() => {
    const handleScroll = () => {
      const logoSectionHeight = 16 * window.innerHeight / 100; // 16vh in pixels
      if (!isMobile) {
        setIsNavbarSticky(window.scrollY > logoSectionHeight);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change breakpoint as needed
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    // Dispatch action to fetch phones
    dispatch(fetchPhones());

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, dispatch]);

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'tr' ? 'en' : 'tr');
  };

  // Get the first phone if available
  const phone = phones.length > 0 ? phones[0] : null;

  return (
    <>
      {/* Logo Section (AppBar) */}
      <div className="container-fluid" style={{ backgroundColor: 'black', height: '10vh', padding: '0' }}>
        <div className="row align-items-center" style={{ height: '100%' }}>
          {!isMobile ? (
            <div className='col-12 d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center' style={{ gap: '1rem' }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ height: '100%', maxHeight: '8vh', objectFit: 'contain' }}
                />
              </div>
              <div className="d-flex align-items-center" style={{ gap: '3rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <div className="d-flex align-items-center" style={{ color: 'white', gap: '0.5rem' }}>
                    <FaEnvelope size={20} />
                    <span>E-mail: isttekzemin@gmail.com</span>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <div className="d-flex align-items-center" style={{ color: 'white', gap: '0.5rem' }}>
                    <FaPhone size={20} />
                    <span>
                      {loading ? 'Loading phone...' : phone ? `${t('navbar.phone')}: ${phone.value}` : 'Phone not found'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
                <button
                  onClick={toggleLanguage}
                  style={{
                    marginInlineEnd: '20px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white'
                  }}
                >
                  <FlagIcon code="TR" size={20} />
                  <span style={{ margin: '0 0.25rem' }}>|</span>
                  <FlagIcon code="GB" size={20} />
                </button>
              </div>
            </div>
          ) : (
            <div className='d-flex align-items-center justify-content-start' style={{ padding: '0 1rem' }}>
              <img
                src={logo}
                alt="Logo"
                style={{ height: '100%', maxHeight: '8vh', objectFit: 'contain', marginRight: '0.5rem' }}
              />
              <div className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
                <button
                  onClick={toggleLanguage}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white'
                  }}
                >
                  <FlagIcon code="TR" size={20} />
                  <span style={{ margin: '0 0.25rem' }}>|</span>
                  <FlagIcon code="GB" size={20} />
                </button>
                <button
                  className="navbar-toggler bg-body"
                  data-bs-theme="dark"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarText"
                  aria-controls="navbarText"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  style={{ marginLeft: '1rem' }}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Navbar */}
      <nav
        className={`navbar navbar-expand-lg ${isNavbarSticky ? 'sticky' : ''}`}
        style={{
          top: '-8px',
          backgroundColor: 'white',
          width: '100%',
          position: 'relative',
          transition: 'background-color 0.3s ease',
          zIndex: '1020',
          margin: '0',
        }}
      >
        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="collapse navbar-collapse justify-content-center" id="navbarText">
            <ul className="navbar-nav" style={{ gap: '1rem' }}>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">{t('navbar.homepage')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/hakkimizda">{t('navbar.about')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/hizmetlerimiz">{t('navbar.services')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/project">{t('navbar.project')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/iletisim">{t('navbar.communication')}</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Custom Styles */}
      <style>{`
        .sticky {
          position: fixed;
          width: 100%;
          top: 0;
        }

        /* Desktop View: White Text Initially, Black on Scroll */
        @media (min-width: 768px) {
          .navbar-nav .nav-link {
            font-weight: bold;
            color: black;
            text-decoration: none;
            transition: color 0.2s ease, text-decoration 0.2s ease;
          }

          .navbar-nav .nav-link:hover {
            text-decoration: underline;
          }

          /* Sticky nav links turn black on scroll */
          .sticky .navbar-nav .nav-link {
            color: black;
          }

          /* Additional margin-top for desktop navbar */
          .navbar {
            margin-top: 1rem; /* Adjust as needed */
          }
        }

        /* Mobile View: Black Text */
        @media (max-width: 768px) {
          .navbar-nav .nav-link {
            color: black;
          }
        }
      `}</style>
    </>
  );
};

export default AppBar;
