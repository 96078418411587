import React, { useEffect } from 'react';
import './Footer.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAddresses } from '../../store/feature/adressSlice'; // Redux slice import for addresses
import { fetchPhones } from '../../store/feature/phoneSlice'; // Redux slice import for phones
import { useTranslation } from 'react-i18next';
import { AppDispatch } from '../../store';

function Footer() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  // Redux store'dan adres ve telefon bilgilerini almak
  const { addresses, loading: addressLoading, error: addressError } = useSelector((state: any) => state.adress); // 'adress' slice'ını state'ten alıyoruz
  const { phones, loading: phoneLoading, error: phoneError } = useSelector((state: any) => state.phoneSlice); // 'phone' slice'ını state'ten alıyoruz
  
  const token = localStorage.getItem('token'); // Token'ı localStorage'dan alıyoruz

  // Adresleri sayfa yüklendiğinde çekme işlemi
  useEffect(() => {
    if (token) {
      dispatch(fetchAddresses(token)); // Token varsa adresleri çek
      dispatch(fetchPhones()); // Token varsa telefonları çek
    }
  }, [dispatch, token]);

  return (
    <footer className="footer-section">
      <div className="footer-container">
        {/* Sol: Logo */}
        <div className="footer-left">
          <img src="../../ist-tek_zemin.png" alt="Logo" className="footer-logo" />
        </div>

        {/* Orta: Adresler */}
        <div className="footer-center">
          <h3>{t('footer.adress')}</h3>
          {addressLoading ? (
            <p>{t('footer.loading')}</p>
          ) : addressError ? (
            <p>{t('footer.error')}</p>
          ) : (
            <div className="address-list">
              {addresses.map((address: { id: number; description: string; value: string }, index: number) => (
                <div key={address.id} className={`address-item ${index !== addresses.length - 1 ? 'has-border' : ''}`}>
                  <p>{address.value}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Sağ: İletişim Bilgileri (Telefonlar ve Mail) */}
        <div className="footer-right">
          <h3>{t('footer.communication')}</h3>
          {phoneLoading ? (
            <p>{t('footer.loading')}</p>
          ) : phoneError ? (
            <p>{t('footer.error')}</p>
          ) : (
            <div className="phone-list">
              {phones.map((phone: { id: number; value: string }) => (
                <p key={phone.id}>
                  <a href={`tel:${phone.value}`}>{phone.value}</a>
                </p>
              ))}
            </div>
          )}
          {/* Mail Section */}
          <div className="email-section">
            <a href="mailto:isttekzemin@gmail.com">isttekzemin@gmail.com</a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-company">
          <span>© 2024 CortexSoft</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
