import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../constants/Config';

const FULL_BASE_URL = `${BASE_URL}/dev/v1/project`;

// Define Project interface
export interface Project {
  id: number;
  employer: string;
  title: string;
  location: string;
  date: string;
  description: string;
}

interface ProjectsState {
  projects: Project[];
  loading: boolean;
  error: string | null;
}

// Define initial state
const initialState: ProjectsState = {
  projects: [], // Initial empty array for projects
  loading: false,
  error: null,
};

// Define async actions (thunks) for API calls

// Fetch all projects
export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${FULL_BASE_URL}/findall`);
      if (!response.ok) {
        throw new Error('Projeler alınamadı');
      }
      const data = await response.json();
      return data; // Return the fetched data
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

// Save a new project
export const saveProject = createAsyncThunk(
  'projects/save',
  async (
    { token, employer, title, location, date, description }: 
    { token: string; employer: string; title: string; location: string; date: string; description: string }
  ) => {
    const response = await axios.post(
      `${FULL_BASE_URL}/save`, 
      {
        token, // Token body'de gönderiliyor
        employer,
        title,
        location,
        date,
        description,
      },
      {
        headers: {
          'Content-Type': 'application/json', // JSON formatında içerik tipi
        },
      }
    );


    return response.data.data; // Kaydedilen proje bilgileri döndürülüyor
  }
);



// Delete a project
export const deleteProject = createAsyncThunk(
  'projects/delete',
  async ({ projectId, token }: { projectId: number; token: string }) => {
    const response = await axios.delete(`${FULL_BASE_URL}/delete`, {
      headers: {
        'Content-Type': 'application/json', // JSON format for deleting
      },
      data: {
        token,           // Send token in the body
        projectId,       // Send the ID of the project to be deleted
      },
    });

    if (!response.data || !response.data.data) {
      throw new Error('Project could not be deleted.');
    }

    return projectId; // Return the project ID to remove it from Redux state
  }
);

// Create slice
const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        console.log('Redux payload:', action.payload); // Check the payload here
        state.projects = action.payload;
        state.loading = false;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(saveProject.fulfilled, (state, action: PayloadAction<Project[]>) => {
        console.log('Payload in reducer:', action.payload);
        state.projects = action.payload; // Update state with new list of projects
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.projects = state.projects.filter(
          (project) => project.id !== action.payload // Remove project by ID
        );
      });
  },
});

export default projectsSlice.reducer;
