import React, { useState } from "react";
import "./AdminPanel.css";
import EnterAboutUs from "../components/molecules/EnterAboutUs";
import ServiceAddPage from "./ServiceAddPage";
import OurServicesList from "../components/molecules/OurServicesList";
import PhonePage from "../components/molecules/PhonePage";
import AddressPage from "../components/molecules/AdressPage";
import ProjectForm from "../components/molecules/AddProjectPage";
import ProjectsList from "../components/molecules/ProjectsList";
import { Button } from "@mui/material"; // Material-UI Button import
import Swal from 'sweetalert2'; // SweetAlert2 import
import AdminCommentPage from "../components/molecules/AdminCommentPage";

function AdminPanel() {
  const [activeSection, setActiveSection] = useState<string>("dashboard");

  const renderContent = (): JSX.Element => {
    switch (activeSection) {
      case "addservice":
        return <ServiceAddPage />;
      case "addproject":
        return <ProjectForm />;
      case "aboutus":
        return <EnterAboutUs />;
      case "ourservices":
        return <OurServicesList />;
      case "ourprojects":
        return <ProjectsList />;
      case "phonepage":
        return <PhonePage />;
      case "adresspage":
        return <AddressPage />;
      case "commentapprove":
        return <AdminCommentPage />;
      default:
        return <h2>Welcome to the Admin Panel</h2>;
    }
  };

  const handleLogout = () => {
    // SweetAlert2 ile onay penceresi
    Swal.fire({
      title: 'Emin misiniz?',
      text: "Çıkış yapmak istediğinizden emin misiniz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33', // Kırmızı renk
      cancelButtonColor: '#3085d6', // Mavi renk
      confirmButtonText: 'Evet, çıkış yap',
      cancelButtonText: 'Hayır, iptal et'
    }).then((result) => {
      if (result.isConfirmed) {
        // Kullanıcı 'Evet' diyorsa, çıkış işlemini yap
        window.location.href = "/";
      }
    });
  };

  return (
      <div className="admin-panel">
        {/* Sidebar */}
        <div className="sidebar">
          <button onClick={() => setActiveSection("addservice")}>Hizmet ekle</button>
          <button onClick={() => setActiveSection("ourservices")}>Hizmetlerimiz</button>
          <button onClick={() => setActiveSection("addproject")}>Proje ekle</button>
          <button onClick={() => setActiveSection("ourprojects")}>Projelerimiz</button>
          <button onClick={() => setActiveSection("aboutus")}>Hakkımızda</button>
          <button onClick={() => setActiveSection("phonepage")}>Telefon</button>
          <button onClick={() => setActiveSection("adresspage")}>Adres</button>
          <button onClick={() => setActiveSection("commentapprove")}>Yorum onayla</button>

          {/* Material-UI çıkış butonu */}
          <Button
              variant="contained" // Buton tipi: doldurulmuş (contained)
              color="error" // Kırmızı renk (error rengi)
              onClick={handleLogout}
              style={{
                position: 'absolute', // Butonu ekranın alt kısmına yerleştirmek için absolute kullanıyoruz
                bottom: '20px', // Ekranın alt kısmına 20px boşluk bırakıyoruz
                left: '50%',
                transform: 'translateX(-50%)', // Butonu ortalamak için
                background: "red"
              }}
          >
            Çıkış Yap
          </Button>
        </div>

        {/* Content */}
        <div className="content">{renderContent()}</div>
      </div>
  );
}

export default AdminPanel;
