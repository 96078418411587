import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AboutUsSection from "./pages/AboutPage";
import AdminPanel from "./pages/AdminPanel";
import PrivateRoute from "./privateroute"; // PrivateRoute import edildi
import OurServicesPage from "./pages/OurServicesPage";
import ServiceDetailPage from "./pages/ServicesDetailPage";
import ProjectDetails from "./components/molecules/ProjectDetails";
import Contact from "./pages/Contact";
import ForgotPassword from "./pages/ForgotPassword";
import ProjectsPage from "./pages/ProjectsPage";
import NotFoundPage from "./pages/NotFoundPage"; // 404 sayfası import edildi

function RouterPage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hakkimizda" element={<AboutUsSection />} />
        <Route path="/hizmetlerimiz" element={<OurServicesPage />} />
        <Route path="/services" element={<OurServicesPage />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/project" element={<ProjectsPage />} />
        <Route path="/services/:title" element={<ServiceDetailPage />} />
        <Route path="/project/:title" element={<ProjectDetails />} />
        <Route
          path="/adminpanel"
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          }
        />
        {/* 404 Sayfası */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RouterPage;
