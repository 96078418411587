import { configureStore } from "@reduxjs/toolkit";
import { useSelector} from 'react-redux';
import authSlice from "./feature/authSlice";
import aboutusSlice  from "./feature/aboutusSlice";
import ourservicesSlice from "./feature/ourservicesSlice";
import { adressSlice, commentSlice, phoneSlice , projectSlice } from "./feature";
const store = configureStore({
    reducer:{
       
        auth: authSlice,
        aboutus : aboutusSlice,
        ourservices : ourservicesSlice,
        phoneSlice : phoneSlice,
        adress : adressSlice,
        projects : projectSlice,
        comments : commentSlice
    }
  });
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector = useSelector.withTypes<RootState>();
export default store;