import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { saveAddress, fetchAddresses, deleteAddress } from '../../store/feature/adressSlice';

const AddressPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // State for the address value
  const [value, setValue] = useState('');

  // Redux'tan gelen adres verileri
  const { addresses, loading, error } = useSelector((state: RootState) => state.adress);

  // Token'ı localStorage'dan al
  const token = localStorage.getItem('token'); // Token string | null

  // Sayfa yüklendiğinde adresleri çek
  useEffect(() => {
    if (token) {
      dispatch(fetchAddresses(token));
    }
  }, [dispatch, token]);

  // Adres kaydetme
  const handleSave = () => {
    if (!token) {
      alert('Token bulunamadı. Lütfen giriş yapın!');
      return;
    }
    if (!value) {
      alert('Lütfen değeri girin!');
      return;
    }

    // Save address and update the state by refetching addresses
    dispatch(saveAddress({ token, description: '', value })).then(() => {
      // Refetch addresses after saving the new one
      dispatch(fetchAddresses(token));
    });
    setValue('');
  };

  // Adres silme
  const handleDelete = (id: number) => {
    if (!token) {
      alert('Token bulunamadı. Lütfen giriş yapın!');
      return;
    }
    dispatch(deleteAddress({ token, id })).then(() => {
      // After deleting, refetch addresses to update the list
      dispatch(fetchAddresses(token));
    });
  };

  return (
    <div style={styles.container}>
      <h2>Adres Ekle</h2>
      <div style={styles.form}>
        <input
          type="text"
          placeholder="Değer"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={styles.input}
        />
        <button onClick={handleSave} style={styles.button}>
          Kaydet
        </button>
      </div>

      <h2>Kayıtlı Adresler</h2>
      {loading ? (
        <p>Yükleniyor...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <ul style={styles.list}>
          {addresses.map((address) => (
            <li key={address.id} style={styles.listItem}>
              <span>{address.value}</span> {/* Display only the value */}
              <button onClick={() => handleDelete(address.id)} style={styles.deleteButton}>
                Sil
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: '80%',
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  form: {
    marginBottom: '20px',
  },
  input: {
    margin: '5px',
    padding: '10px',
    width: '200px',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    paddingBottom: '5px',
  },
  deleteButton: {
    padding: '5px 10px',
    fontSize: '14px',
    backgroundColor: 'red',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};

export default AddressPage;
