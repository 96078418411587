import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "./store";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { token } = useAppSelector((state) => state.auth); // Token Redux'tan alınır.

  // Eğer token yoksa, giriş sayfasına yönlendirilir
  if (!token) {
    return <Navigate to="/admin" replace />;
  }

  return children; // Token varsa, hedef bileşeni render eder
};

export default PrivateRoute;
