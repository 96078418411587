import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../../constants/Config";

const FULL_BASE_URL = `${BASE_URL}/dev/v1/phone`;

// Telefon kaydetme
export const savePhone = createAsyncThunk(
  "phone/save",
  async ({ token, description, value }: { token: string; description: string; value: string }, { rejectWithValue }) => {
    try {
      const payload = { token, description, value };
      await axios.post(`${FULL_BASE_URL}/save`, payload);
      return "Telefon başarıyla kaydedildi.";
    } catch (error: any) {
      return rejectWithValue("Telefon kaydedilirken bir hata oluştu.");
    }
  }
);

// Telefon güncelleme
export const updatePhone = createAsyncThunk(
  "phone/update",
  async (
    { token, phoneId, description, value }: { token: string; phoneId: number; description: string; value: string },
    { rejectWithValue }
  ) => {
    try {
      const payload = { token, phoneId, description, value };
      await axios.put(`${FULL_BASE_URL}/update`, payload);
      return "Telefon başarıyla güncellendi.";
    } catch (error: any) {
      return rejectWithValue("Telefon güncellenirken bir hata oluştu.");
    }
  }
);

// Telefon silme
export const deletePhone = createAsyncThunk(
  "phone/delete",
  async ({ token, id }: { token: string; id: number }, { rejectWithValue }) => {
    try {
      await axios.delete(`${FULL_BASE_URL}/delete`, { params: { token, id } });
      return id; // Silinen telefonun ID'sini döndürüyoruz
    } catch (error: any) {
      return rejectWithValue("Telefon silinirken bir hata oluştu.");
    }
  }
);

// Telefonları listeleme
export const fetchPhones = createAsyncThunk("phone/fetchAll", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${FULL_BASE_URL}/findall`);
    return response.data; // Backend'den gelen telefon listesi
  } catch (error: any) {
    return rejectWithValue("Telefonlar alınırken bir hata oluştu.");
  }
});

// Slice tanımı
const phoneSlice = createSlice({
  name: "phone",
  initialState: {
    phones: [] as any[], // Telefonları tutan dizi
    message: "",
    loading: false,
    error: "",
  },
  reducers: {
    clearMessage: (state) => {
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Telefon kaydetme
      .addCase(savePhone.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(savePhone.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload as string;
      })
      .addCase(savePhone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Telefon güncelleme
      .addCase(updatePhone.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updatePhone.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload as string;
      })
      .addCase(updatePhone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Telefon silme
      .addCase(deletePhone.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deletePhone.fulfilled, (state, action) => {
        state.loading = false;
        state.message = "Telefon başarıyla silindi.";
        // Silinen telefonu listeden kaldır
        state.phones = state.phones.filter((phone) => phone.id !== action.payload);
      })
      .addCase(deletePhone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Telefonları listeleme
      .addCase(fetchPhones.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPhones.fulfilled, (state, action) => {
        state.loading = false;
        state.phones = action.payload; // Telefon listesini kaydet
      })
      .addCase(fetchPhones.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearMessage } = phoneSlice.actions;
export default phoneSlice.reducer;
