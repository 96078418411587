import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../../constants/Config";

// Backend API URL'si
const FULL_BASE_URL = `${BASE_URL}/dev/v1/auth`; // Gerekirse backend URL'nizi güncelleyin

// Tip tanımlamaları
export interface IAuth {
    id: number;
    email: string;
}

interface IFetchForgotPassword {
    email: string;
}

interface IFetchResetPassword {
    code: string;
    newPassword: string;
    rePassword: string;
}

// Login işlemi için thunk
export const login = createAsyncThunk(
    "auth/login",
    async (credentials: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${FULL_BASE_URL}/login`, credentials);
            return response.data.data; // Token dönecek
        } catch (error: any) {
            return rejectWithValue(
                error.response?.data?.message || "Giriş başarısız. Lütfen tekrar deneyin."
            );
        }
    }
);

// Şifre sıfırlama işlemi için thunk
export const fetchForgotPassword = createAsyncThunk(
    'auth/fetchForgotPassword',
    async (payload: IFetchForgotPassword, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${BASE_URL}/forget-password?email=${payload.email}`, null, {
                headers: { 'Content-Type': 'application/json' }
            });
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue({ message: 'Bir hata oluştu.' });
            }
        }
    }
);

// Şifre sıfırlama işlemi için kod ve şifre güncelleme işlemi
export const fetchResetPassword = createAsyncThunk(
    'auth/fetchResetPassword',
    async (payload: IFetchResetPassword, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${BASE_URL}/reset-password`, payload, {
                headers: { 'Content-Type': 'application/json' }
            });
            return response.data;
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue({ message: 'An error occurred during the verification process.' });
            }
        }
    }
);

// Kullanıcı verisini almak için thunk
export const getAuth = createAsyncThunk<IAuth, void, { rejectValue: string }>(
    "auth/getAuth",
    async (_, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return rejectWithValue("Token bulunamadı.");
            }

            const response = await axios.get(`${BASE_URL}/find-all`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            return response.data as IAuth; // Backend'den dönen kullanıcı verisi
        } catch (error: any) {
            console.error("API Hatası:", error.response || error.message);
            return rejectWithValue(
                error.response?.data?.message || error.message || "Kullanıcı verisi alınırken bir hata oluştu."
            );
        }
    }
);

// State tip tanımlamaları
interface AuthState {
    token: string | null;
    loading: boolean;
    error: string | null;
    resetMessage: string | null; // Şifre sıfırlama mesajı
    authData: IAuth | null; // Kullanıcı verisi
}

const initialState: AuthState = {
    token: null,
    loading: false,
    error: null,
    resetMessage: null,
    authData: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null;
            state.authData = null;
            localStorage.removeItem("token");
        },
        clearResetMessage: (state) => {
            state.resetMessage = null; // Şifre sıfırlama mesajını temizle
        },
    },
    extraReducers: (builder) => {
        builder
            // Login işlemleri
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload;
                localStorage.setItem("token", action.payload); // Token'ı local storage'a kaydet
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            // Şifre sıfırlama işlemleri
            .addCase(fetchForgotPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.resetMessage = null;
            })
            .addCase(fetchForgotPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.resetMessage = action.payload; // Şifre sıfırlama mesajını alıyoruz
            })
            .addCase(fetchForgotPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            // Reset şifre işlemleri
            .addCase(fetchResetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchResetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.resetMessage = action.payload?.message || "Şifre başarıyla sıfırlandı!";
            })
            .addCase(fetchResetPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            // Kullanıcı verisi alma işlemleri
            .addCase(getAuth.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAuth.fulfilled, (state, action) => {
                state.loading = false;
                state.authData = action.payload;
            })
            .addCase(getAuth.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { logout, clearResetMessage } = authSlice.actions;

export default authSlice.reducer;
