import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../../constants/Config";

const FULL_BASE_URL = `${BASE_URL}/dev/v1/aboutus`;

// Hakkımızda içeriğini API'den getir
export const fetchAboutUs = createAsyncThunk("aboutUs/fetch", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${FULL_BASE_URL}/find`);
    return response.data.content; // Backend'den dönen içeriği alıyoruz
  } catch (error: any) {
    return rejectWithValue("Sunucuya bağlanırken bir hata oluştu.");
  }
});

// Hakkımızda içeriğini kaydet
export const saveAboutUs = createAsyncThunk(
  "aboutUs/save",
  async ({ token, content }: { token: string; content: string }, { rejectWithValue }) => {
    try {
      const payload = { token, content };
      await axios.post(`${FULL_BASE_URL}/save-or-update`, payload);
      return "Hakkımızda içeriği başarıyla güncellendi.";
    } catch (error: any) {
      return rejectWithValue("Hakkımızda içeriğini kaydederken bir hata oluştu.");
    }
  }
);

// Slice tanımı
const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState: {
    content: "",
    message: "",
    loading: false,
    error: "",
  },
  reducers: {
    updateContent: (state, action) => {
      state.content = action.payload;
    },
    clearMessage: (state) => {
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutUs.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAboutUs.fulfilled, (state, action) => {
        state.loading = false;
        state.content = action.payload;
      })
      .addCase(fetchAboutUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(saveAboutUs.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(saveAboutUs.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload as string;
      })
      .addCase(saveAboutUs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { updateContent, clearMessage } = aboutUsSlice.actions;
export default aboutUsSlice.reducer;
