import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAboutUs, saveAboutUs, updateContent } from "../../store/feature/aboutusSlice";
import { RootState, AppDispatch } from "../../store";
import "./EnterAboutUs.css";

function EnterAboutUs() {
  const dispatch = useDispatch<AppDispatch>();
  const { content, message, loading } = useSelector((state: RootState) => state.aboutus);

  useEffect(() => {
    dispatch(fetchAboutUs());
  }, [dispatch]);

  // Kullanıcıdan alınan içeriği paragraf düzenine göre formatlama fonksiyonu
  const formatContent = (rawContent: string) => {
    return rawContent
      .split("\n") // Yeni satırlara göre ayır
      .map((line) => line.trim()) // Her satırdaki gereksiz boşlukları temizle
      .filter((line) => line !== "") // Boş satırları çıkar
      .map((line) => `<p>${line}</p>`) // Her satırı <p> etiketine sar
      .join(""); // Tek bir string olarak birleştir
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Lütfen giriş yapın.");
      return;
    }
    const formattedContent = formatContent(content); // İçeriği formatla
    dispatch(saveAboutUs({ token, content: formattedContent }));
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateContent(e.target.value));
  };

  return (
    <div className="enter-about-us">
      <h2>Hakkımızda İçeriğini Düzenle</h2>
      {loading && <p>Yükleniyor...</p>}
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <textarea
          value={content}
          onChange={handleContentChange}
          placeholder="Hakkımızda içeriğini girin"
          rows={10}
        ></textarea>
        <button type="submit" disabled={loading}>Kaydet</button>
      </form>
      <div className="content-preview">
        <h3>Önizleme:</h3>
        <div className="preview-area" dangerouslySetInnerHTML={{ __html: formatContent(content) }}></div>
      </div>
    </div>
  );
}

export default EnterAboutUs;
