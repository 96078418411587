import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Link } from "@mui/material";
import { AppDispatch, useAppSelector } from "../store";
import { login } from "../store/feature/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, token } = useAppSelector((state) => state.auth);

    const handleLogin = async () => {
        dispatch(login({ email, password }));
    };

    useEffect(() => {
        if (token) {
            navigate("/adminpanel");
        }
    }, [token, navigate]);

    const handleForgotPassword = () => {
        navigate("/forgot-password");
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                background: "linear-gradient(to right, #6a11cb, #2575fc)", // Background gradient
                padding: 2,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: 500, // Increase max width for a larger form
                    margin: "auto",
                    padding: 4,
                    borderRadius: 3,
                    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
                    backgroundColor: "white",
                    textAlign: "center",
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3 }}>
                    Admin Panel Girişi
                </Typography>
                <Typography sx={{ mb: 3, fontSize: "1.1rem", color: "gray" }}>
                    Lütfen kullanıcı adı ve şifrenizi giriniz.
                </Typography>
                {error && <Typography color="error" sx={{ mb: 3 }}>{error}</Typography>}
                <TextField
                    fullWidth
                    label="E-mail Adresiniz"
                    variant="outlined"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 2, fontSize: "1.1rem" }}
                />
                <TextField
                    fullWidth
                    label="Şifreniz"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mb: 3, fontSize: "1.1rem" }}
                />
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        mt: 3,
                        backgroundColor: "#2575fc",
                        "&:hover": { backgroundColor: "#6a11cb" },
                        padding: "15px",
                        fontSize: "1.1rem",
                    }}
                    onClick={handleLogin}
                    disabled={loading}
                >
                    {loading ? "Giriş Yapılıyor..." : "Giriş Yap"}
                </Button>

                <Link
                    href="#"
                    onClick={handleForgotPassword}
                    sx={{
                        display: "block",
                        mt: 3,
                        textAlign: "center",
                        color: "#2575fc",
                        fontSize: "1.1rem",
                        "&:hover": { textDecoration: "underline" },
                    }}
                >
                    Şifremi Unuttum
                </Link>
            </Box>
        </Box>
    );
};

export default Login;
